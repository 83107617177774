(function() {
    'use strict';

    angular.module('ionicForceRefresh', [
        'ionic',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicForceRefresh').controller('IonicForceRefreshController', IonicForceRefreshController);

    IonicForceRefreshController.$inject = [
        'DefaultVariableService',
        '$ionicScrollDelegate',
        '$q',
        '$scope'
    ];

    function IonicForceRefreshController(
        DefaultVariableService,
        $ionicScrollDelegate,
        $q,
        $scope
    ) {
        var IonicForceRefreshController = this;

        $scope.$watch(
            'refreshFunction',
            function(refreshFunction) {
                IonicForceRefreshController.refreshFunction = DefaultVariableService.getPromise(
                    refreshFunction
                );
            }.bind(IonicForceRefreshController)
        );

        IonicForceRefreshController.forceRefresh = forceRefresh;
        function forceRefresh() {
            return $q.resolve(
                IonicForceRefreshController.refreshFunction()
            ).finally(
                function() {
                    $scope.$broadcast('scroll.refreshComplete');

                    $ionicScrollDelegate.resize();
                }
            );
        }

        IonicForceRefreshController.reset = reset;
        function reset() {
            IonicForceRefreshController.pullText = DefaultVariableService.get(
                IonicForceRefreshController.pullText,
                'PULL TO REFRESH...'
            );
        }

        IonicForceRefreshController.init = init;
        function init() {
            IonicForceRefreshController.reset();
        }

        IonicForceRefreshController.init();
    }
})();
